<template>
  <!-- <div class="app-container"> -->
   
  <!-- A000000企业基础信息表 -->
      <div class="big_box4">
        <el-scrollbar :height="contentStyleObj">
       
        <div class="top_title3">
          <h4>企业所得税年度纳税申报基础信息表</h4>
          <!--   -->
        </div>
        <table class="content" cellspacing="0" width="100%">
          <tr class="top_bg">
            <td class="center" colspan="7">基本经营情况（必填项目) </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">101纳税申报企业类型（填写代码）</td>
            <td class="width_small blue_box"><input type="text" v-model="baseInfo[0].a101"></td>
            <td class="width_big" colspan="2">102分支机构就地纳税比例（%）</td>
            <td class="blue_box width_small" colspan="2"><input type="text" v-model.number="baseInfo[0].a102"></td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">103资产总额（填写平均值，单位：万元）</td>
            <td class="width_small blue_box"><input type="text" v-model.number="baseInfo[0].a103"></td>
            <td class="width_big" colspan="2">104从业人数（填写平均值，单位：人）</td>
            <td class="width_small blue_box" colspan="2"><input type="text" v-model="baseInfo[0].a104"></td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">105所属国民经济行业（填写代码）</td>
            <td class="width_small blue_box"><input type="text" v-model="baseInfo[0].a105"></td>
            <td class="width_big" colspan="2">106从事国家限制或禁止行业</td>
            <td class="width_small" colspan="2">
              <el-radio v-model="baseInfo[0].a106" label="是">是</el-radio>
              <el-radio v-model="baseInfo[0].a106" label="否">否</el-radio>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">107适用会计准则或会计制度（填写代码）</td>
            <td class="blue_box width_small"><input type="text" v-model="baseInfo[0].a107"></td>
            <td class="width_big" colspan="2">108采用一般企业财务报表格式（2019年版）</td>
            <td class="width_small" colspan="2">
              <el-radio v-model="baseInfo[0].a108" label="是">是</el-radio>
              <el-radio v-model="baseInfo[0].a108" label="否">否</el-radio>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">109小型微利企业</td>
            <td class="width_small">
              <el-radio v-model="baseInfo[0].a109" label="是">是</el-radio>
              <el-radio v-model="baseInfo[0].a109" label="否">否</el-radio>
            </td>
            <td class="width_big" colspan="2">110上市公司</td>
            <td class="width_small" colspan="2">
              <el-radio v-model="baseInfo[0].a110" label="境内上市">境内上市</el-radio>
              <el-radio v-model="baseInfo[0].a110" label="境外上市">境外上市</el-radio>
              <el-radio v-model="baseInfo[0].a110" label="否">否</el-radio>
            </td>
          </tr>

          <tr class="top_bg">
            <td class="center" colspan="7">有关涉税事项情况（存在或者发生下列事项时必填）</td>
          </tr>

          <tr>
            <td class="width_big" colspan="2">201从事股权投资业务</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a201">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">202存在境外关联交易</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a202">是</el-checkbox>
            </td>
          </tr>
          <tr>
            <td class="width_big" rowspan= '2'>203境外所得信息</td>
            <td class="width_big" colspan="7">203-1选择采用的境外所得抵免方式
              <el-radio v-model="baseInfo[0].a2031" label="分国（地区）不分项" style="margin-left:40px">分国（地区）不分项</el-radio>
              <el-radio v-model="baseInfo[0].a2031" label="不分国（地区）不分项">不分国（地区）不分项</el-radio>
              <el-radio v-model="baseInfo[0].a2031" label="否">否</el-radio>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">203-2海南自由贸易港新增境外直接投资信息</td>
            <td colspan="5">
              <el-checkbox v-model="baseInfo[0].a20321" @change="changeStatus">是</el-checkbox>
              （产品类型：<el-radio v-model="baseInfo[0].a20322" label="1" :disabled="disabled">旅游业</el-radio><el-radio v-model="baseInfo[0].a20322" label="2" :disabled="disabled">现代服务业</el-radio><el-radio v-model="baseInfo[0].a20322" label="3" :disabled="disabled">高新技术产业</el-radio>）
            </td>
          </tr>

          <tr>
            <td class="width_big" colspan="2">204有限合伙制创业投资企业的法人合伙人</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a204">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">205创业投资企业</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a205">是</el-checkbox>
            </td>
          </tr>

          <tr>
            <td class="width_big" colspan="2">206技术先进型服务企业类型（填写代码）</td>
            <td class="width_small blue_box"><input type="text" v-model="baseInfo[0].a206"></td>
            <td class="width_big" colspan="2">207非营利组织</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a207">是</el-checkbox>
            </td>
          </tr>

          <tr>
            <td class="width_small" colspan="2">208软件、集成电路企业类型（填写代码）</td>
            <td class="width_small blue_box"><input type="text" v-model="baseInfo[0].a208"></td>
            <td class="width_small">209集成电路生产项目类型</td>
            <td class="width_big" colspan="3">
              <el-radio v-model="baseInfo[0].a209" label="130纳米">130纳米</el-radio>
              <el-radio v-model="baseInfo[0].a209" label="65纳米">65纳米</el-radio>
              <el-radio v-model="baseInfo[0].a209" label="28纳米">28纳米</el-radio>
            </td>
          </tr>
          <tr>
            <td class="width_ss" rowspan= '2'>210科技型中小企业</td>
            <td class="width_big input_width" colspan="2">
              <span>2010-1：<input type="text" class="blue_box" v-model="baseInfo[0].a21001">年（申报所属期年度）入库编号1</span>
            </td>
            <td class="width_ss blue_box"><input type="text" v-model="baseInfo[0].a210num01"></td>
            <td class="width_ss">210-2入库时间1</td>
            <td class="width_small blue_box" colspan="2"><input type="text" v-model="baseInfo[0].a21002"></td>
          </tr>
          <tr>
            <td class="width_big input_width" colspan="2">
              <span>2010-3：<input type="text" class="blue_box" v-model="baseInfo[0].a21003">年（申报所属期年度）入库编号2</span>
            </td>
            <td class="width_ss blue_box"><input type="text" v-model="baseInfo[0].a210num02"></td>
            <td class="width_ss">210-4入库时间2</td>
            <td class="width_small blue_box" colspan="2"><input type="text" v-model="baseInfo[0].a21004"></td>
          </tr>

          <tr>
            <td class="width_small" rowspan= '2' colspan="2">211高新技术企业申报所属期年度<br>有效的高新技术企业证书</td>
            <td class="width_small">211-1 证书编号1</td>
            <td class="width_ss blue_box"><input type="text" v-model="baseInfo[0].a21101"></td>
            <td class="width_ss">211-2发证时间1</td>
            <td class="width_small blue_box" colspan="2"><input type="text" v-model="baseInfo[0].a21102"></td>
          </tr>
          <tr>
            <td class="width_small">211-3 证书编号2</td>
            <td class="width_ss blue_box"><input type="text" v-model="baseInfo[0].a21103"></td>
            <td class="width_ss">211-4发证时间2</td>
            <td class="width_small blue_box" colspan="2"><input type="text" v-model="baseInfo[0].a21104"></td>
          </tr>
          <tr>
            <td class="width_small" colspan="2">212重组事项税务处理方式</td>
            <td class="width_small">
              <el-radio v-model="baseInfo[0].a212" label="一般性">一般性</el-radio>
              <el-radio v-model="baseInfo[0].a212" label="特殊性">特殊性</el-radio>

            </td>
            <td class="width_big" colspan="2">213重组交易类型（填写代码）</td>
            <td class="width_small border_none blue_box no_bg_color" colspan="2">
              <input type="text" v-model="baseInfo[0].a213">
            </td>
          </tr>
          <tr>
            <td class="width_small" colspan="2">214重组当事方类型（填写代码）</td>
            <td class="width_small border_none blue_box no_bg_color">
              <input type="text" v-model="baseInfo[0].a214">
            </td>
            <td class="width_big" colspan="2">215政策性搬迁开始时间</td>
            <td class="width_small blue_box" colspan="2">
            <input type="text" v-model="baseInfo[0].a215">
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">216发生政策性搬迁且停止生产经营无所得年度</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a216">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">217政策性搬迁损失分期扣除年度</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a217">是</el-checkbox>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">218发生非货币性资产对外投资递延纳税事项</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a218">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">219非货币性资产对外投资转让所得递延纳税年度</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a219">是</el-checkbox>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">220发生技术成果投资入股递延纳税事项</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a220">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">221技术成果投资入股递延纳税年度</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a221">是</el-checkbox>
            </td>
          </tr>
          <tr>
            <td class="width_big" colspan="2">222发生资产（股权）划转特殊性税务处理事项</td>
            <td class="width_small">
              <el-checkbox v-model="baseInfo[0].a222">是</el-checkbox>
            </td>
            <td class="width_big" colspan="2">223债务重组所得递延纳税年度</td>
            <td class="width_small" colspan="2">
              <el-checkbox v-model="baseInfo[0].a223">是</el-checkbox>
            </td>
          </tr> 
          <tr>
            <td class="width_big" colspan="2">224研发支出辅助账样式</td>
            <td class="width_small">
              <el-radio v-model="baseInfo[0].a224" label="2015版">2015版</el-radio>
              <el-radio v-model="baseInfo[0].a224" label="2021版">2021版</el-radio>
              <el-radio v-model="baseInfo[0].a224" label="自行设计">自行设计</el-radio>
              <el-radio v-model="baseInfo[0].a224" label="否">否</el-radio>
            </td>
          </tr> 
          <!-- <tr>
            <td class="width_ss">股东名称</td>
            <td class="width_ss">证件类型</td>
            <td class="width_small">证件号码</td>
            <td class="width_ss">投资比例</td>
            <td class="width_medium" colspan="2">当年（决议日）分配的股息、红利等权益性投资收益金额</td>
            <td class="width_ss">国籍（注册地址）</td>
          </tr>-->
        </table>
        <div class="save_box">
          <el-button type="primary" size="small" @click="saveInfo()" v-if="this.tableYearName"><i class="iconfont icon-baocun"></i>保存</el-button>
        </div>
        <table class="content item_box" cellspacing="0" width="98%" style="width:98%;margin: 0;">
          <tr class="top_bg">
            <td class="center" colspan="7">主要股东及分红情况（必填项目）</td>
          </tr>
          <div class="add" @click="handleAddItem(info,$index)">
            <el-icon><Plus /></el-icon>
          </div>
          <tr>
            <td class="width_ss font_cen">股东名称</td>
            <td class="width_ss font_cen">证件类型</td>
            <td class="width_ss font_cen">证件号码</td>
            <td class="width_ss font_cen">投资比例</td>
            <td style="width:17%" colspan="2" class="font_cen"> 当年（决议日）分配的股息、红利等权益性投资收益金额</td>
            <td class="width_xs font_cen">国籍（注册地址）</td>
          </tr>
          <tr v-for="(row,$index) in info" :key="$index" >
          <!-- <tr> -->
            <td class="blue_box"><input type="text" v-model="row.gdName"></td>
            <td class="blue_box"><input type="text" v-model="row.gdType"></td>
            <td class="blue_box"><input type="text" v-model="row.gdNo"></td>
            <td class="blue_box"><input size="mini" v-model="row.bl"></td>
            <td class="blue_box" colspan="2"><input size="mini" v-model="row.amount"></td>
            <td class="blue_box close_item"><input type="text" v-model="row.country">
              <div class="close" @click="handleDelItem(info,$index)">
                <el-icon><Minus /></el-icon>
              </div>
            </td>
          </tr>
          <tr>
            <td class="gary_box">其他股东合计</td>
            <td class="font_cen">————</td>
            <td class="font_cen">————</td>
            <td class="gary_box">{{totalAmount1}}</td>
            <td class="gary_box" colspan="2">{{totalAmount2}}</td>
            <td class="font_cen">————</td>
          </tr>
        </table>
        <div class="save_box">
          <el-button v-if="this.tableYearName" type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
        </div>
      </el-scrollbar>
      </div>
      
      <!-- A000000企业基础信息表 end -->
   
  <!-- </div> -->

</template>
<script>
import { yearGdInfo, yearGdEdit, yearInfo, yearEdit ,yearInfoCj } from "@/api/year";
export default {
  name: "A000000",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  computed: {
    //金额合计
    totalAmount1() {
      let bl = 0;
      this.info.map(v => {
        if (v.bl) {
          bl += Number(v.bl);
        }
      });
      return Number(bl.toFixed(2))
      // return Number(totalAmount.toFixed(2));
    },
    totalAmount2() {
      let totalAmount = 0;
      this.info.map(v => {
        if (v.amount) {
          totalAmount += Number(v.amount);
        }
      });
      return Number(totalAmount.toFixed(2))
      // return Number(totalAmount.toFixed(2));
    },
  },
  data() {
    return {
      options: [{
        value: '1',
        label: '法律形式改变'
      }, {
        value: '2',
        label: '债务重组'
      }, {
        value: '3',
        label: '股权收购'
      }, {
        value: '4',
        label: '资产收购'
      }, {
        value: '5',
        label: '合并'
      }],
      value: '',
      radio: '1',
      checked: true,
      comName: '',
      taxNo: '',
      durationOfTaxTime: '',
      amount:'',
      info:[
        {
          gdName: "", // 股东名称
          gdType: "", // 证件类型
          gdNo: "", // 证件号码
          bl: "", // 投资比例
          amount: "", // 收益金额
          country: "" // 国籍
        },
      ],
      baseInfo:[{"a101":''}],
      disabled:false,
      contentStyleObj:{}

    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
    this.contentStyleObj = this.$getHeight(310)
    this.getDetail()
    this.getParams()
  },
  methods: {
    // 获取基本信息表
    getParams() {
      // const tableName = this.$route.params.tableName;
      if(this.tableYearName){
        this.getInfo({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableYearName})
        this.listLoading = true;
        return
      }
      const tableName = 'year_a000000'
      this.tableName = tableName
      if (tableName) {
        this.getInfo({ tableName });
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    // 基本信息
    getInfo(e) {
      if(this.tableYearName){
        yearInfoCj(e).then(response => {
          this.baseInfo = response.data.data;
          this.baseInfo.map(v=>{
            if(v.a201 == 'Y'){
              v.a201 = true
            } else{
              v.a201 = false
            }
            if(v.a202 == 'Y'){
              v.a202 = true
            } else{
              v.a202 = false
            }
            if(v.a204 == 'Y'){
              v.a204 = true
            } else{
              v.a204 = false
            }
            if(v.a205 == 'Y'){
              v.a205 = true
            } else{
              v.a205 = false
            }
            if(v.a207 == 'Y'){
              v.a207 = true
            } else{
              v.a207 = false
            }
            if(v.a216 == 'Y'){
              v.a216 = true
            } else{
              v.a216 = false
            }
            if(v.a217 == 'Y'){
              v.a217 = true
            } else{
              v.a217 = false
            }
            if(v.a218 == 'Y'){
              v.a218 = true
            } else{
              v.a218 = false
            }
            if(v.a219 == 'Y'){
              v.a219 = true
            } else{
              v.a219 = false
            }
            if(v.a220 == 'Y'){
              v.a220 = true
            } else{
              v.a220 = false
            }
            if(v.a221 == 'Y'){
              v.a221 = true
            } else{
              v.a221 = false
            }
            if(v.a222 == 'Y'){
              v.a222 = true
            } else{
              v.a222 = false
            }
            if(v.a223 == 'Y'){
              v.a223 = true
            } else{
              v.a223 = false
            }
            if(v.a20321 == 'Y'){
              v.a20321 = true
              this.disabled = false
            } else{
              v.a20321 = false
              this.disabled = true
            }
            
          })
        });
      return
      }
      yearInfo(e).then(response => {
        this.baseInfo = response.data.data;
        this.baseInfo.map(v=>{
          if(v.a201 == 'Y'){
            v.a201 = true
          } else{
            v.a201 = false
          }
          if(v.a202 == 'Y'){
            v.a202 = true
          } else{
            v.a202 = false
          }
          if(v.a204 == 'Y'){
            v.a204 = true
          } else{
            v.a204 = false
          }
          if(v.a205 == 'Y'){
            v.a205 = true
          } else{
            v.a205 = false
          }
          if(v.a207 == 'Y'){
            v.a207 = true
          } else{
            v.a207 = false
          }
          if(v.a216 == 'Y'){
            v.a216 = true
          } else{
            v.a216 = false
          }
          if(v.a217 == 'Y'){
            v.a217 = true
          } else{
            v.a217 = false
          }
          if(v.a218 == 'Y'){
            v.a218 = true
          } else{
            v.a218 = false
          }
          if(v.a219 == 'Y'){
            v.a219 = true
          } else{
            v.a219 = false
          }
          if(v.a220 == 'Y'){
            v.a220 = true
          } else{
            v.a220 = false
          }
          if(v.a221 == 'Y'){
            v.a221 = true
          } else{
            v.a221 = false
          }
          if(v.a222 == 'Y'){
            v.a222 = true
          } else{
            v.a222 = false
          }
          if(v.a223 == 'Y'){
            v.a223 = true
          } else{
            v.a223 = false
          }
          if(v.a20321 == 'Y'){
            v.a20321 = true
            this.disabled = false
          } else{
            v.a20321 = false
            this.disabled = true
          }
          
        })
      });
    },
    // 更改状态、
    changeStatus(e){
      if(e == false){
        this.disabled = true
        this.baseInfo[0].a20322 = ''
      } else {
        this.disabled = false
      }
    },
    
    // 点击保存
    saveInfo(){
      this.fullscreenLoading = true
      this.baseInfo.map(v=>{
        if(v.a201 == true){
          v.a201 = 'Y'
        } else{
          v.a201 = ''
        }
        if(v.a202 == true){
          v.a202 = 'Y'
        } else{
          v.a202 = ''
        }
        if(v.a204 == true){
          v.a204 = 'Y'
        } else{
          v.a204 = ''
        }
        if(v.a205 == true){
          v.a205 = 'Y'
        } else{
          v.a205 = ''
        }
        if(v.a207 == true){
          v.a207 = 'Y'
        } else{
          v.a207 = ''
        }
        if(v.a216 == true){
          v.a216 = 'Y'
        } else{
          v.a216 = ''
        }
        if(v.a217 == true){
          v.a217 = 'Y'
        } else{
          v.a217 = ''
        }
        if(v.a218 == true){
          v.a218 = 'Y'
        } else{
          v.a218 = ''
        }
        if(v.a219 == true){
          v.a219 = 'Y'
        } else{
          v.a219 = ''
        }
        if(v.a220 == true){
          v.a220 = 'Y'
        } else{
          v.a220 = ''
        }
        if(v.a221 == true){
          v.a221 = 'Y'
        } else{
          v.a221 = ''
        }
        if(v.a222 == true){
          v.a222 = 'Y'
        } else{
          v.a222 = ''
        }
        if(v.a223 == true){
          v.a223 = 'Y'
        } else{
          v.a223 = ''
        }
        if(v.a20321 == true){
          v.a20321 = 'Y'
        } else{
          v.a20321 = ''
        }
        v.a102 = Number(v.a102)
        v.a103 = Number(v.a103)
        v.a104 = Number(v.a104)
      })
      yearEdit({tableName:this.tableName,items:this.baseInfo}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getInfo({tableName:'year_a000000'})
      });
    },
    // 添加一行
    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        gdName: "", // 股东名称
        gdType: "", // 证件类型
        gdNo: "", // 证件号码
        bl: "", // 投资比例
        amount: "", // 收益金额
        country: "" // 国籍
      });
    },
    // 删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    getDetail() {
      if(this.tableYearName){
        yearInfoCj({comId:this.comId,year:this.startAccountPeriod,tableName:'year_a000000_gd_cj'}).then(response => {
          if(response.data.data.length > 0){
            this.info = response.data.data;
          }
        });
        return
      }
      yearGdInfo({}).then(response => {
        if(response.data.data.length > 0){
          this.info = response.data.data;
        }
      });
    },
    // 点击保存
    saveDetail(){
      this.info.map(v=>{
        v.bl = Number(v.bl);
        v.amount = Number(v.amount);
      })
      this.fullscreenLoading = true
      yearGdEdit({comId:this.comId,period:this.startAccountPeriod,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail()
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.font_cen{
  text-align: center;
}
.el-input__inner{
  width: 100% !important;
  background-color: none !important;
}

.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 12px !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.width_big{
  width: 20%;
}
.width_small{
  width: 20%;
}
.width_medium{
  width: 18%;
}
.width_ss{
  width: 15%;
}
.width_xs{
  width:13%;
}
.gary_box{
  background: #f6f6f6;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
  padding-right: 15px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding-left: 6px;
    font-size: 14px;
  }
}
.left_k{
  text-indent: 2em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
.item_box {
  position: relative;
  margin-top:30px !important;
  border-top: 0 !important;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 16px;
    color: #409EFF;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.close_item{
  position: relative;
  .close {
    // width: 1.4em;
    // height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -24px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
</style>



